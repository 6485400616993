var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loadingState.rating)?_c('loader',{attrs:{"size":64}}):_c('BoostLayout',{attrs:{"is-bg-rating":true}},[_c('div',{staticClass:"main"},[_c('div',{staticClass:"ratings-container"},[_c('BaseLink',{staticClass:"rating-link",attrs:{"to":{ name: 'boost' }}},[_c('Icon',{staticClass:"arrow",attrs:{"name":"arrow-left","inline":""}}),_vm._v(" "+_vm._s(_vm.$t('global.back'))+" ")],1),_c('div',{staticClass:"headline-wrapper"},[_c('h2',{staticClass:"rating-title"},[_vm._v(_vm._s(_vm.pageTitle))]),_c('div',{staticClass:"rating-headline"},[_c('a',{staticClass:"social-link",attrs:{"href":"https://vk.com/boostesports","target":"_blank"}},[_c('Icon',{attrs:{"name":"vk"}})],1)])]),[_c('TabList',{staticClass:"tabs",attrs:{"tabs":_vm.tabs},on:{"change-tab":_vm.switchTables}}),_c('Table',{directives:[{name:"show",rawName:"v-show",value:(_vm.isPlayers && _vm.tabNumber === 0),expression:"isPlayers && tabNumber === 0"}],staticClass:"rating-table",scopedSlots:_vm._u([{key:"thead",fn:function(){return [_c('TableRow',_vm._l((_vm.commonHeadersPlayers),function(header,idx){return _c('TableData',{key:idx,attrs:{"heading":true,"text-align":idx === 1 ? 'left' : 'center'}},[_vm._v(" "+_vm._s(header)+" ")])}),1)]},proxy:true},{key:"tbody",fn:function(){return _vm._l((_vm.rating.items),function(rowData,index){return _c('TableRow',{key:index},[_c('TableData',[_vm._v(" "+_vm._s(rowData.place)+" ")]),_c('TableData',{attrs:{"text-align":"left"}},[_c('BaseUserLinkWithoutStore',{attrs:{"data":{
                    hash: rowData.hash,
                    name: rowData.name,
                    avatarUrl: rowData.image,
                  }}})],1),_c('TableData',[_vm._v(" "+_vm._s(_vm.numberToFixed(rowData.finedValue, 2))+" ")]),_c('TableData',[_vm._v(" "+_vm._s(rowData.kills)+" ")]),_c('TableData',[_vm._v(" "+_vm._s(rowData.deaths)+" ")]),_c('TableData',[_vm._v(" "+_vm._s(_vm.numberToFixed(rowData.killsDeaths, 1))+" ")]),_c('TableData',[_vm._v(" "+_vm._s(rowData.roundsPlayed)+" ")]),_c('TableData',[_vm._v(" "+_vm._s(rowData.roundsWithKills)+" ")]),_c('TableData',[_vm._v(" "+_vm._s(_vm.numberToFixed(rowData.adr))+" ")]),_c('TableData',[_vm._v(" "+_vm._s(rowData.killsMinusDeaths)+" ")]),_c('TableData',[_vm._v(" "+_vm._s(rowData.headShotPercent ? _vm.numberToFixed(rowData.headShotPercent) : '-')+" ")])],1)})},proxy:true}])}),_c('Table',{directives:[{name:"show",rawName:"v-show",value:(_vm.isPlayers && _vm.tabNumber === 1),expression:"isPlayers && tabNumber === 1"}],staticClass:"rating-table",scopedSlots:_vm._u([{key:"thead",fn:function(){return [_c('TableRow',_vm._l((_vm.openingKillsHeaders),function(header,idx){return _c('TableData',{key:idx,attrs:{"heading":true,"text-align":idx === 1 ? 'left' : 'center'}},[_vm._v(" "+_vm._s(header)+" ")])}),1)]},proxy:true},{key:"tbody",fn:function(){return _vm._l((_vm.rating.items),function(rowData,index){return _c('TableRow',{key:index},[_c('TableData',[_vm._v(" "+_vm._s(rowData.place)+" ")]),_c('TableData',{attrs:{"text-align":"left"}},[_c('BaseUserLinkWithoutStore',{attrs:{"data":{
                    hash: rowData.hash,
                    name: rowData.name,
                    avatarUrl: rowData.image,
                  }}})],1),_c('TableData',[_vm._v(" "+_vm._s(rowData.openFrags)+" ")]),_c('TableData',[_vm._v(" "+_vm._s(rowData.firstDeaths)+" ")]),_c('TableData',[_vm._v(" "+_vm._s(_vm.numberToFixed(rowData.openFragsDeaths, 2))+" ")])],1)})},proxy:true}])}),_c('Table',{directives:[{name:"show",rawName:"v-show",value:(_vm.isPlayers && _vm.tabNumber === 2),expression:"isPlayers && tabNumber === 2"}],staticClass:"rating-table",scopedSlots:_vm._u([{key:"thead",fn:function(){return [_c('TableRow',_vm._l((_vm.roundHeaders),function(header,idx){return _c('TableData',{key:idx,attrs:{"heading":true,"text-align":idx === 1 ? 'left' : 'center'}},[_vm._v(" "+_vm._s(header)+" ")])}),1)]},proxy:true},{key:"tbody",fn:function(){return _vm._l((_vm.rating.items),function(rowData,index){return _c('TableRow',{key:index},[_c('TableData',[_vm._v(" "+_vm._s(rowData.place)+" ")]),_c('TableData',{attrs:{"text-align":"left"}},[_c('BaseUserLinkWithoutStore',{attrs:{"data":{
                    hash: rowData.hash,
                    name: rowData.name,
                    avatarUrl: rowData.image,
                  }}})],1),_c('TableData',[_vm._v(" "+_vm._s(rowData.rounds0Kills)+" ")]),_c('TableData',[_vm._v(" "+_vm._s(rowData.rounds1Kills)+" ")]),_c('TableData',[_vm._v(" "+_vm._s(rowData.rounds2Kills)+" ")]),_c('TableData',[_vm._v(" "+_vm._s(rowData.rounds3Kills)+" ")]),_c('TableData',[_vm._v(" "+_vm._s(rowData.rounds4Kills)+" ")]),_c('TableData',[_vm._v(" "+_vm._s(rowData.rounds5Kills)+" ")])],1)})},proxy:true}])}),_c('Table',{directives:[{name:"show",rawName:"v-show",value:(_vm.isTeam),expression:"isTeam"}],staticClass:"rating-table",scopedSlots:_vm._u([{key:"thead",fn:function(){return [_c('TableRow',_vm._l((_vm.commonHeadersTeams),function(header,idx){return _c('TableData',{key:idx,attrs:{"heading":true,"text-align":idx === 1 ? 'left' : 'center'}},[_vm._v(" "+_vm._s(header)+" ")])}),1)]},proxy:true},{key:"tbody",fn:function(){return _vm._l((_vm.rating.items),function(rowData,index){return _c('TableRow',{key:index},[_c('TableData',[_vm._v(" "+_vm._s(rowData.place)+" ")]),_c('TableData',{attrs:{"text-align":"left"}},[_c('BaseTeamLinkWithoutStore',{attrs:{"data":{
                    hash: rowData.hash,
                    name: rowData.name,
                    avatarUrl: rowData.image,
                  }}})],1),_c('TableData',{attrs:{"text-align":"left"}},[_c('country-tag',{attrs:{"id":rowData.idCountry}})],1),_c('TableData',[_vm._v(" "+_vm._s(rowData.matches)+" ")]),_c('TableData',[_vm._v(" "+_vm._s(rowData.win)+" ")]),_c('TableData',[_vm._v(" "+_vm._s(rowData.percentWin)+" ")]),_c('TableData',[_vm._v(" "+_vm._s(rowData.finedValue)+" ")]),_c('TableData',[_vm._v(" "+_vm._s(_vm._f("filterFloat")((rowData.value - rowData.finedValue),5))+" "),(rowData.penaltyDescription)?_c('pvp-btn',{attrs:{"icon-left":"info","variant":"clear"},on:{"click":function($event){return _vm.showPenaltyPopup(rowData)}}}):_vm._e()],1)],1)})},proxy:true}])}),(_vm.showPagination)?_c('pagination',{staticClass:"pagination",attrs:{"params":_vm.paginationParams,"disabled":_vm.loadingState.rating},on:{"onchange":_vm.setPage}}):_vm._e()],[_c('p',{staticClass:"seo-text"},[_vm._v(_vm._s(_vm.seoText))])]],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }