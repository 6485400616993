<template>
  <loader v-if="loadingState.rating" :size="64" />
  <BoostLayout v-else :is-bg-rating="true">
    <div class="main">
      <div class="ratings-container">
        <BaseLink :to="{ name: 'boost' }" class="rating-link">
          <Icon class="arrow" name="arrow-left" inline />
          {{ $t('global.back') }}
        </BaseLink>

        <div class="headline-wrapper">
          <h2 class="rating-title">{{ pageTitle }}</h2>
          <div class="rating-headline">
            <a
              class="social-link"
              href="https://vk.com/boostesports"
              target="_blank"
            >
              <Icon name="vk" />
            </a>
          </div>
        </div>
        <template>
          <TabList
            class="tabs"
            :tabs="tabs"
            @change-tab="switchTables"
          />

          <!-- Таблица рейтинга игроков -->
          <Table
            v-show="isPlayers && tabNumber === 0"
            class="rating-table"
          >
            <template #thead>
              <TableRow>
                <TableData
                  v-for="(header, idx) in commonHeadersPlayers"
                  :key="idx"
                  :heading="true"
                  :text-align="idx === 1 ? 'left' : 'center'"
                >
                  {{ header }}
                </TableData>
              </TableRow>
            </template>
            <template #tbody>
              <TableRow
                v-for="(rowData, index) in rating.items"
                :key="index"
              >
                <TableData>
                  {{ rowData.place }}
                </TableData>
                <TableData text-align="left">
                  <BaseUserLinkWithoutStore
                    :data="{
                      hash: rowData.hash,
                      name: rowData.name,
                      avatarUrl: rowData.image,
                    }"
                  />
                </TableData>
                <TableData>
                  {{ numberToFixed(rowData.finedValue, 2) }}
                </TableData>
                <TableData>
                  {{ rowData.kills }}
                </TableData>
                <TableData>
                  {{ rowData.deaths }}
                </TableData>
                <TableData>
                  {{ numberToFixed(rowData.killsDeaths, 1) }}
                </TableData>
                <TableData>
                  {{ rowData.roundsPlayed }}
                </TableData>
                <TableData>
                  {{ rowData.roundsWithKills }}
                </TableData>
                <TableData>
                  {{ numberToFixed(rowData.adr) }}
                </TableData>
                <TableData>
                  {{ rowData.killsMinusDeaths }}
                </TableData>
                <TableData>
                  {{
                    rowData.headShotPercent
                      ? numberToFixed(rowData.headShotPercent)
                      : '-'
                  }}
                </TableData>
              </TableRow>
            </template>
          </Table>

          <Table
            v-show="isPlayers && tabNumber === 1"
            class="rating-table"
          >
            <template #thead>
              <TableRow>
                <TableData
                  v-for="(header, idx) in openingKillsHeaders"
                  :key="idx"
                  :heading="true"
                  :text-align="idx === 1 ? 'left' : 'center'"
                >
                  {{ header }}
                </TableData>
              </TableRow>
            </template>
            <template #tbody>
              <TableRow
                v-for="(rowData, index) in rating.items"
                :key="index"
              >
                <TableData>
                  {{ rowData.place }}
                </TableData>
                <TableData text-align="left">
                  <BaseUserLinkWithoutStore
                    :data="{
                      hash: rowData.hash,
                      name: rowData.name,
                      avatarUrl: rowData.image,
                    }"
                  />
                </TableData>
                <TableData>
                  {{ rowData.openFrags }}
                </TableData>
                <TableData>
                  {{ rowData.firstDeaths }}
                </TableData>
                <TableData>
                  {{ numberToFixed(rowData.openFragsDeaths, 2) }}
                </TableData>
              </TableRow>
            </template>
          </Table>

          <Table
            v-show="isPlayers && tabNumber === 2"
            class="rating-table"
          >
            <template #thead>
              <TableRow>
                <TableData
                  v-for="(header, idx) in roundHeaders"
                  :key="idx"
                  :heading="true"
                  :text-align="idx === 1 ? 'left' : 'center'"
                >
                  {{ header }}
                </TableData>
              </TableRow>
            </template>
            <template #tbody>
              <TableRow
                v-for="(rowData, index) in rating.items"
                :key="index"
              >
                <TableData>
                  {{ rowData.place }}
                </TableData>
                <TableData text-align="left">
                  <BaseUserLinkWithoutStore
                    :data="{
                      hash: rowData.hash,
                      name: rowData.name,
                      avatarUrl: rowData.image,
                    }"
                  />
                </TableData>
                <TableData>
                  {{ rowData.rounds0Kills }}
                </TableData>
                <TableData>
                  {{ rowData.rounds1Kills }}
                </TableData>
                <TableData>
                  {{ rowData.rounds2Kills }}
                </TableData>
                <TableData>
                  {{ rowData.rounds3Kills }}
                </TableData>
                <TableData>
                  {{ rowData.rounds4Kills }}
                </TableData>
                <TableData>
                  {{ rowData.rounds5Kills }}
                </TableData>
              </TableRow>
            </template>
          </Table>

          <!-- Таблица командного рейтинга -->
          <Table v-show="isTeam" class="rating-table">
            <template #thead>
              <TableRow>
                <TableData
                  v-for="(header, idx) in commonHeadersTeams"
                  :key="idx"
                  :heading="true"
                  :text-align="idx === 1 ? 'left' : 'center'"
                >
                  {{ header }}
                </TableData>
              </TableRow>
            </template>
            <template #tbody>
              <TableRow
                v-for="(rowData, index) in rating.items"
                :key="index"
              >
                <TableData>
                  {{ rowData.place }}
                </TableData>
                <TableData text-align="left">
                  <BaseTeamLinkWithoutStore
                    :data="{
                      hash: rowData.hash,
                      name: rowData.name,
                      avatarUrl: rowData.image,
                    }"
                  />
                </TableData>
                <TableData text-align="left">
                  <country-tag :id="rowData.idCountry" />
                </TableData>
                <TableData>
                  {{ rowData.matches }}
                </TableData>
                <TableData>
                  {{ rowData.win }}
                </TableData>
                <TableData>
                  {{ rowData.percentWin }}
                </TableData>
                <TableData>
                  {{ rowData.finedValue }}
                </TableData>
                <TableData>
                  {{
                    (rowData.value - rowData.finedValue)
                      | filterFloat(5)
                  }}
                  <pvp-btn
                    v-if="rowData.penaltyDescription"
                    icon-left="info"
                    variant="clear"
                    @click="showPenaltyPopup(rowData)"
                  />
                </TableData>
              </TableRow>
            </template>
          </Table>

          <pagination
            v-if="showPagination"
            class="pagination"
            :params="paginationParams"
            :disabled="loadingState.rating"
            @onchange="setPage"
          />
        </template>

        <template>
          <p class="seo-text">{{ seoText }}</p>
        </template>
      </div>
    </div>
  </BoostLayout>
</template>

<script>
import BoostLayout from '@src/components/v2/BoostLayout.vue';
import TabList from '@src/components/v2/TabList.vue';
import Table from '@components/v2/ui/Table.vue';
import TableRow from '@components/v2/ui/TableRow.vue';
import TableData from '@components/v2/ui/TableData.vue';
import Pagination from '@src/components/v2/Pagination.vue';
import Icon from '@components/v2/utils/Icon.vue';
import BaseTeamLinkWithoutStore from '@components/BaseComponents/BaseTeamLinkWithoutStore.vue';
import BaseUserLinkWithoutStore from '@components/BaseComponents/BaseUserLinkWithoutStore.vue';
import BaseLink from '@components/BaseComponents/BaseLink.vue';

import { fetchRatingById } from '@src/shared/api/rating';

export default {
  name: 'BoostRatingItemPage',
  components: {
    BoostLayout,
    TabList,
    Table,
    TableRow,
    TableData,
    Pagination,
    Icon,
    BaseTeamLinkWithoutStore,
    BaseUserLinkWithoutStore,
    BaseLink,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
      loadingState: {
        rating: true,
      },
      tabNumber: 0,
      rating: {},
      structure: [],
      tabsPlayers: [
        {
          title: this.$t('ratings.currentStatistic'),
          type: 'general',
        },
        {
          title: this.$t('ratings.openingKills'),
          type: 'openingKills',
        },
        {
          title: this.$t('ratings.rounds'),
          type: 'roundsKills',
        },
      ],
      tabsTeams: [
        {
          title: this.$t('ratings.currentStatistic'),
          type: 'general',
        },
      ],
      commonIdsPlayers: [
        'place',
        'hash',
        'value',
        'kills',
        'deaths',
        'killsDeaths',
        'roundsPlayed',
        'roundsWithKills',
        'adr',
        'killsMinusDeaths',
        'headShotPercent',
      ],
      commonIdsTeams: [
        'place',
        'hash',
        'idCountry',
        'matches',
        'win',
        'percentWin',
        'finedValue',
        'penalties',
      ],
      openingKillsIds: [
        'place',
        'hash',
        'openFrags',
        'firstDeaths',
        'openFragsDeaths',
      ],
      roundsIds: [
        'place',
        'hash',
        'rounds0Kills',
        'rounds1Kills',
        'rounds2Kills',
        'rounds3Kills',
        'rounds4Kills',
        'rounds5Kills',
      ],
      descriptionModalOpened: false,

      // Pagination
      currentPage: 1,
      perPage: 12,
      total: 0,
      perPageSizes: [12, 36, 72],
    };
  },
  computed: {
    isTeam() {
      return this.rating?.type === 2;
    },

    isPlayers() {
      return this.rating?.type === 1;
    },

    tabs() {
      return this.isPlayers ? this.tabsPlayers : this.tabsTeams;
    },

    commonHeadersPlayers() {
      return this.getHeaderNamesFromStructure(this.commonIdsPlayers);
    },

    commonHeadersTeams() {
      return this.getHeaderNamesFromStructure(this.commonIdsTeams);
    },

    openingKillsHeaders() {
      return this.getHeaderNamesFromStructure(this.openingKillsIds);
    },

    roundHeaders() {
      return this.getHeaderNamesFromStructure(this.roundsIds);
    },

    pageTitle() {
      return this.isTeam
        ? this.$t('ratings.teamsRating')
        : this.$t('ratings.playersRating');
    },
    seoText() {
      return this.$t('seo.ratingPage', {
        host: window.location.host.toLocaleUpperCase(),
      });
    },
    paginationParams() {
      return {
        page: this.currentPage,
        perPage: this.perPage,
        total: this.total,
        perPageSizes: this.perPageSizes,
        showBy: this.$t('places.showBy'),
      };
    },
    showPagination() {
      return this.total > 12;
    },
    shareTitle() {
      return this.$t('ratings.share', {
        name: this.rating.name,
        host: window.location.host.toLocaleUpperCase(),
      });
    },
  },
  watch: {
    perPage: {
      handler: 'reloadRating',
    },
    currentPage: {
      handler: 'reloadRating',
      immediate: true,
    },
  },
  methods: {
    getHeaderNamesFromStructure(ids) {
      return ids
        .reduce((acc, id) => {
          const objFromStructure = this.structure.find(
            (obj) => obj.itemIds === id,
          );
          if (objFromStructure) {
            acc.push(objFromStructure);
          }
          return acc;
        }, [])
        .map(({ header }) => header);
    },

    numberToFixed(value, fixedNum = 0) {
      const number = Number(value);
      return number.toFixed(fixedNum);
    },

    switchTables(data) {
      const { index } = data;
      this.tabNumber = index;
    },

    setPage({ page, perPage }) {
      this.perPage = perPage;
      this.currentPage = page;
    },

    reloadRating() {
      this.loadingState.rating = true;
      return this.loadRatingItem();
    },

    async loadRatingItem() {
      const responseData = await fetchRatingById({
        pageNum: this.currentPage,
        pageSize: this.perPage,
        id: this.id,
      });

      this.rating = {
        ...responseData.rating,
        items: responseData.items,
      };
      this.structure = responseData.structure;
      this.total = responseData.pagen.totalCount;
      this.loadingState.rating = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.ratings-container {
  max-width: 1200px;
  margin: 0 auto;
}
.rating-table {
  margin-bottom: 8px;

  @include min-tablet() {
    margin-bottom: 16px;
  }
}
.rating-link {
  display: inline-block;
  padding-top: 36px;
  margin-bottom: 20px;
  @include min-tablet() {
    padding-top: 60px;
  }
}
.headline-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tabs {
  margin-bottom: 16px;
}
.social-link {
  cursor: pointer;
  font-size: 35px;
  color: rgba(white, 0.5);
  line-height: 1;

  &:hover {
    color: rgba(white, 1);
  }
}
.pagination {
  margin-bottom: 30px;
  @include min-tablet() {
    margin-bottom: 20px;
  }
}
.seo-text {
  color: #979797;
  margin-bottom: 30px;
  @include min-tablet() {
    margin-bottom: 20px;
  }
}
.alignLeft {
  text-align: left;
}
</style>
