import { render, staticRenderFns } from "./RatingItemPage.vue?vue&type=template&id=128cc297&scoped=true"
import script from "./RatingItemPage.vue?vue&type=script&lang=js"
export * from "./RatingItemPage.vue?vue&type=script&lang=js"
import style0 from "./RatingItemPage.vue?vue&type=style&index=0&id=128cc297&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "128cc297",
  null
  
)

export default component.exports