<template>
  <main-layout class="padded" :is-loading="loadingState.page">
    <div class="pvp-container">
      <div class="ratings-container">
        <h2 class="ratings-title">{{ $t('ratings.few') }}</h2>
        <section class="games-filter-section">
          <games-filter
            v-model="gamesIds"
            @input="gamesFilterInputHandler"
          >
            <template slot="title">{{ $t('games.filter') }}</template>
          </games-filter>
        </section>
        <div class="ratings-container__tab-list">
          <TabList :tabs="tabs" @change-tab="tabListChangeHandler" />
        </div>
        <template v-if="loadingState.ratingsPage">
          <div class="loader-container">
            <loader />
          </div>
        </template>
        <template v-else>
          <template v-if="currentTabIndex === 0">
            <template v-if="ratings.length">
              <div class="ratings-table-list">
                <div
                  v-for="rating in ratings"
                  :key="rating.id"
                  class="ratings-table-container"
                >
                  <h4 class="rating-table__title">
                    <BaseLink
                      :to="{
                        name: 'rating',
                        params: { id: rating.id },
                      }"
                      @click-native="ratingClickHandler(rating.name)"
                    >
                      {{ rating.name }}
                    </BaseLink>
                  </h4>

                  <RatingTable :rating="rating" />

                  <div class="ratings-container__button">
                    <pvp-btn
                      :to="{
                        name: 'rating',
                        params: { id: rating.id },
                      }"
                      :block="true"
                      class="about-rating-button"
                      variant="secondary"
                      @click-native="ratingClickHandler(rating.name)"
                      >{{ $t('ratings.more') }}
                    </pvp-btn>
                  </div>
                </div>
              </div>
            </template>
            <div v-else class="rating-placeholder">
              <div class="placeholder-icon">
                <icon name="pvp-swords"></icon>
              </div>
              <div class="placeholder-text">
                {{ $t('ratings.placeholderByGames') }}
              </div>
            </div>
            <pvp-pagination
              v-if="showPagination"
              :params="paginationParams"
              :disabled="loadingState.ratingsPage"
              @onchange="setPage"
            />
          </template>
          <template v-else>
            <UserRatings
              :games-ids="gamesIds"
              :profile-hash="getCurrentProfileHash"
            />
          </template>
        </template>
      </div>
    </div>

    <template slot="seo-text">
      <p>{{ seoText }}</p>
    </template>
  </main-layout>
</template>

<script>
import { pushEvents } from '@utils/metricEvents.js';
import TabList from '@src/components/v2/TabList.vue';
import GamesFilter from '@components/GameComponents/GamesFilter.vue';
import RatingTable from '@components/RatingComponents/RatingTable.vue';
import UserRatings from '@components/RatingComponents/UserRatings.vue';
import Icon from '@components/v2/utils/Icon.vue';
import BaseLink from '@components/BaseComponents/BaseLink.vue';

import { fetchRatingBoards } from '@src/shared/api/rating';

export default {
  name: 'RatingsPage',
  components: {
    GamesFilter,
    RatingTable,
    UserRatings,
    TabList,
    Icon,
    BaseLink,
  },
  props: {
    gameCode: {
      type: String,
      default: '',
    },
  },
  page() {
    return {
      title: this.$t('ratings.few'),
    };
  },
  data() {
    return {
      loadingState: {
        ...this.loadingState,
        ratingsPage: true,
      },
      gamesIds: [],
      currentTabIndex: 0,
      tab: [
        {
          title: this.$t('ratings.playersRatings'),
          type: 0,
        },
      ],
      // Pagination
      currentPage: 1,
      perPage: 9,
      total: 0,
      perPageSizes: [9, 18, 36],
      perPageMobileSizes: [8, 16, 32],
    };
  },
  computed: {
    ...mapGetters('application', [
      'getGameByCode',
      'appIsExtraLarge',
    ]),
    ...mapGetters('profile', [
      'hasMailAccount',
      'getCurrentProfileHash',
    ]),

    seoText() {
      return this.$t('seo.ratings', {
        host: window.location.host.toLocaleUpperCase(),
      });
    },
    tabs() {
      return this.hasMailAccount
        ? [
            ...this.tab,
            {
              title: this.$t('ratings.myRatings'),
              type: 1,
            },
          ]
        : this.tab;
    },

    paginationParams() {
      return {
        page: this.currentPage,
        perPage: this.perPage,
        total: this.total,
        perPageSizes: this.perPageSizes,
      };
    },

    showPagination() {
      return this.total > _.head(this.perPageSizes);
    },
  },
  watch: {
    '$route.query.tab': {
      handler: 'setCurrentTabFromQuery',
      immediate: true,
    },
    currentTabIndex: {
      handler: 'setCurrentTabToQuery',
    },
    perPage: {
      handler: 'reloadRatings',
    },
    currentPage: {
      handler: 'reloadRatings',
      immediate: true,
    },
    gamesIds: {
      handler: 'gamesFilterChanged',
    },
  },
  created() {
    if (!this.appIsExtraLarge) {
      this.perPageSizes = this.perPageMobileSizes;
    }
    this.perPage = _.head(this.perPageSizes);
  },
  methods: {
    gamesFilterInputHandler() {
      this.currentPage = 1;
    },
    tabListChangeHandler({ index }) {
      this.currentTabIndex = index;
    },
    ratingClickHandler(ratingName) {
      // ======================================================
      // METRICS Турниры. Клик по элементу блока популярные игры на главной
      const tmrParams = {
        category: 'pvp',
        action: 'ratings_click',
        label: ratingName,
        url: window.location.href,
      };
      pushEvents('pvp_ratings_click', tmrParams);
      // ======================================================
    },
    setCurrentTabToQuery() {
      this.$router.push({
        query: { ...this.$route.query, tab: this.currentTabIndex },
      });
    },

    gamesFilterChanged(gamesIds) {
      this.gamesIds = gamesIds;
      this.reloadRatings();
    },

    setPage({ page, perPage }) {
      this.currentPage = page;
      this.perPage = perPage;
    },

    reloadRatings() {
      this.loadingState.ratingsPage = true;
      this.loadRatingsData();
    },

    loadRatingsData: async function () {
      const response = await fetchRatingBoards({
        pageNum: this.currentPage,
        pageSize: this.perPage,
        gameId: this.gamesIds.length ? this.gamesIds : null,
      });

      this.ratings = response.items;
      this.total = response.pagen.totalCount;
      this.loadingState.page = false;
      this.loadingState.ratingsPage = false;
    },

    setCurrentTabFromQuery() {
      this.currentTabIndex = Number(this.$route.query.tab) || 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.ratings-container {
  padding-bottom: 32px;
}

.ratings-container__button {
  margin-top: 16px;
}

.ratings-container__tab-list {
  margin-bottom: 32px;
}

.ratings-title {
  font-size: 34px;
  font-weight: bold;
  line-height: normal;
}

.games-filter-section {
  padding: 30px 0;
}

.ratings-table-list {
  display: grid;
  gap: 32px 16px;
  @include min-tablet() {
    grid-template-columns: repeat(2, 1fr);
  }
  @include min-desktop() {
    grid-template-columns: repeat(3, 1fr);
  }

  .rating-table-container {
    .rating-table {
      margin-top: 12px;
    }

    .about-rating-button {
      margin-top: 12px;
    }
  }
}

.rating-table__title {
  display: flex;
  align-items: center;
  height: 44px;
  margin-bottom: 10px;
  font-size: 19px;

  a {
    color: white;
  }

  @include max-tablet() {
    margin-bottom: 20px;
    margin-top: 0;
    align-items: flex-end;
  }
}

.pagination {
  margin-top: 32px;
}

.loader-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.rating-placeholder {
  margin-top: 20px;
  background-color: $dark-background;
  border-radius: 4px;
  box-shadow: $default-box-shadow;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include max-tablet() {
    padding: 12px;
  }

  .placeholder-icon {
    font-size: 30px;
  }

  .placeholder-text {
    text-align: center;
    margin-top: 12px;
    font-weight: bold;
    font-size: 24px;
    @include max-tablet() {
      font-size: 18px;
    }
  }
}
</style>
